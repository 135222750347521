import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import {Form, Button, Container, Col, Card, Spinner} from 'react-bootstrap';
import Map from './utility/Map';
import DeleteSingleEntityButton from './utility/DeleteSingleEntityButton';
import { Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';

export default class AddEditLocation extends Component {
    state = {
        location: undefined,
        divisions: undefined,
        clientId: undefined,
        divisionId: undefined,
        locationId: undefined,
        latitude: '',
        longitude: '',
        redirect: null
    }
    addEditLocation = async (values, actions) => {
        try{
            if(this.state.latitude !== '' && this.state.longitude !== '')
            {
                const myInit = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                    body: {
                        locations: [
                            {
                                ItemId: this.state.location !== undefined ? this.state.location.ItemId : undefined,
                                ParentItemId: values.division,
                                Name: values.locationName,   
                                Division: this.state.divisions.filter(c => c.ItemId === values.division)[0].Name,                     
                                FirstName: values.firstName,
                                LastName: values.lastName,
                                Email: values.email,
                                MobileNumber: values.mobile,
                                Landline: values.landline,
                                FaxNumber: values.faxnumber,
                                Street1: values.street1,
                                Street2: values.street2,
                                Suburb: values.suburb,
                                State: values.state,
                                Zip: values.zip,
                                Notes: values.notes,
                                Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                            }
                        ]
                        }
                };
                //console.log(JSON.stringify(myInit));
                let result;
                if(this.state.location !== undefined)
                    result = await API.patch("api", "/locations", myInit);
                else
                    result = await API.post("api", "/locations", myInit);
                if(result.result === 'success')
                {
                    //this.props.navProp.changeClientPage('locationList', values.division);
                    this.setState({ redirect:'/clients/locationList/' + values.division});
                }                    
                else{
                    actions.setStatus({msg: result.errors[0]});
                    actions.setSubmitting(false);
                }
            }
            else
            {
                actions.setStatus({msg: 'The address provided is not valid.'});
                actions.setSubmitting(false);
            }
        }catch(error)
        {
            console.log('error', error.message);
            actions.setStatus({msg: error.message});
            actions.setSubmitting(false);
        }
    }
    setCoordinates = (latitude, longitude) => {
        this.setState({latitude: latitude, longitude: longitude});
    }
    componentDidMount() {
        this.fetchDivisions();
        //console.log('id', this.props.id);
        if(this.props.match.params.locationId !== undefined && this.props.match.params.locationId.length > 0)
        {
            this.setState({locationId:this.props.match.params.locationId}, function () {
                this.fetchLocation();});
        }
        if(this.props.match.params.divisionId !== undefined && this.props.match.params.divisionId.length > 0 && this.props.match.params.divisionId.startsWith('Division'))
            this.setState({divisionId:this.props.match.params.divisionId});
        if(this.props.match.params.divisionId !== undefined && this.props.match.params.divisionId.length > 0 && this.props.match.params.divisionId.startsWith('Client'))
            this.setState({clientId:this.props.match.params.divisionId});
        // if(this.props.id !== undefined && this.props.id.startsWith('Location'))
        //     this.fetchLocation();
        // if(this.props.id !== undefined && this.props.id.startsWith('Division'))
        //     this.setState({divisionId: this.props.id});
    }
    async fetchLocation() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            const res = await API.get("api", "/locations/" + this.state.locationId, myInit);
            this.setState({
                location: res.locations[0],
                divisionId: res.locations.length > 0 ? res.locations[0].ParentItemId : null
            })
        }catch(err) {
            this.setState(() => { throw err; })
        }
    }
    async fetchDivisions() {
        try{
            const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
            };
            const res = await API.get("api", "/divisions", myInit);
            this.setState({
                divisions: res.divisions
            })
        }catch(err) {
            this.setState(() => { throw err; })
        }
    }
    getDivisions() {        
        return this.state.divisions.map((division, index) => {
           const { Name, ItemId } = division
           return (
               <option key={ItemId} value={ItemId} >{Name}</option>
           )
        })
    }
    render() {
        const schema = yup.object().shape({
            division: yup.string()
                    .required('Required'),
            locationName: yup.string()
                    .required('Required'),
            // firstName: yup.string()
            //         .required('Required'),
            // lastName: yup.string()
            //         .required('Required'),
            // street1: yup.string()
            //         .required('Required'),
            suburb: yup.string()
                    .required('Required'),
            zip: yup.string()
                    .required('Required'),
        });
        if (this.state.redirect) 
            return <Redirect to={{pathname: this.state.redirect}} />
        if((this.state.locationId !== undefined && this.state.location === undefined) || this.state.divisions === undefined)
        {
            return(
                <div><br/><br/><br/>
                <Spinner animation="border" />
                </div>
            );
        }
        else{
            return(
                <Formik
                  validationSchema={schema}
                  onSubmit={async (values, actions) => {
                      await this.addEditLocation(values, actions);
                  }}
                  
                  initialValues={{
                    division: this.state.divisionId !== undefined ? this.state.divisionId : '',
                    locationName: this.state.location === undefined ? '' : this.state.location.Name,
                    firstName: this.state.location === undefined ? '' : this.state.location.FirstName,
                    lastName: this.state.location === undefined ? '' : this.state.location.LastName,
                    email: this.state.location === undefined ? '' : this.state.location.Email,
                    mobile: this.state.location === undefined ? '' : this.state.location.MobileNumber,
                    landline: this.state.location === undefined ? '' : this.state.location.Landline,
                    faxnumber: this.state.location === undefined ? '' : this.state.location.FaxNumber,
                    street1: this.state.location === undefined ? '' : this.state.location.Street1,
                    street2: this.state.location === undefined ? '' : this.state.location.Street2,
                    suburb: this.state.location === undefined ? '' : this.state.location.Suburb,
                    state: this.state.location === undefined ? '' : this.state.location.State,
                    zip: this.state.location === undefined ? '' : this.state.location.Zip,
                    notes: this.state.location === undefined ? '' : this.state.location.Notes
                  }}
                >
                    {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    status,
                    setFieldValue
                  }) => (
                    <Container className="no-left-right-margin form-text-align">
                        <Form onSubmit={handleSubmit}>
                            <Card>
                                <Card.Header><h4>{this.state.locationId !== undefined ? 'Edit Delivery Location' : 'Add Delivery Location'}</h4></Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label><h5>Contact Information</h5></Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>                                        
                                        <Form.Group as={Col} xs={4} controlId="division" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Division</Form.Label>
                                            <Form.Control as="select"
                                                size="sm"
                                                disabled={this.state.location !== undefined}
                                                value={values.division}
                                                name="division"
                                                placeholder="Division"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.division}
                                                touched={touched.division}
                                                isInvalid={errors.division && touched.division}
                                            >
                                            <option value=''>Choose...</option>
                                            {this.getDivisions()}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.division}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="locationName" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Delivery Location Name</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                readOnly={this.state.location !== undefined}
                                                type="text"
                                                value={values.locationName}
                                                name="locationName"
                                                placeholder="Delivery Location Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.locationName}
                                                touched={touched.locationName}
                                                isInvalid={errors.locationName && touched.locationName}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.locationName}
                                            </Form.Control.Feedback>
                                        </Form.Group>                    
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="firstName" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">First Name</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.firstName}
                                                name="firstName"
                                                placeholder="First Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.firstName}
                                                touched={touched.firstName}
                                                isInvalid={errors.firstName && touched.firstName}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.firstName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="lastName" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Last Name</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.lastName}
                                                name="lastName"
                                                placeholder="Last Name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.lastName}
                                                touched={touched.lastName}
                                                isInvalid={errors.lastName && touched.lastName}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.lastName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="email" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">eMail</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.email}
                                                name="email"
                                                placeholder="eMail"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.email}
                                                touched={touched.email}
                                                isInvalid={errors.email && touched.email}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>                                                                  
                                        <Form.Group as={Col} controlId="mobile" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Mobile #</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.mobile}
                                                name="mobile"
                                                placeholder="Mobile #"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.mobile}
                                                touched={touched.mobile}
                                                isInvalid={errors.mobile && touched.mobile}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.mobile}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="landline" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Landline #</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.landline}
                                                name="landline"
                                                placeholder="Landline #"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.landline}
                                                touched={touched.landline}
                                                isInvalid={errors.landline && touched.landline}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.landline}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="faxnumber" className="margin-bottom-8">
                                            <Form.Label className="med-font margin-bottom-zero">Fax #</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={values.faxnumber}
                                                name="faxnumber"
                                                placeholder="Fax #"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={errors.faxnumber}
                                                touched={touched.faxnumber}
                                                isInvalid={errors.faxnumber && touched.faxnumber}
                                            >
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.faxnumber}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4}>
                                            <Form.Row>
                                                <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                                                    <Form.Label><h5>Address</h5></Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} xs={12} controlId="street1" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Street 1</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        value={values.street1}
                                                        name="street1"
                                                        placeholder="Street 1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.street1}
                                                        touched={touched.street1}
                                                        isInvalid={errors.street1 && touched.street1}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.street1}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} xs={12} controlId="street2" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Street 2</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        value={values.street2}
                                                        name="street2"
                                                        placeholder="Street 2"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.street2}
                                                        touched={touched.street2}
                                                        isInvalid={errors.street2 && touched.street2}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.street2}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            
                                                <Form.Group as={Col} xs={12} controlId="suburb" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Suburb</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        value={values.suburb}
                                                        name="suburb"
                                                        placeholder="Suburb"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.suburb}
                                                        touched={touched.suburb}
                                                        isInvalid={errors.suburb && touched.suburb}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.suburb}
                                                    </Form.Control.Feedback>
                                                </Form.Group>                                        
                                                <Form.Group as={Col} xs={12} controlId="state" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">State</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        value={values.state}
                                                        name="state"
                                                        placeholder="State"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.state}
                                                        touched={touched.state}
                                                        isInvalid={errors.state && touched.state}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.state}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} xs={6} controlId="zip" className="margin-bottom-8">
                                                    <Form.Label className="med-font margin-bottom-zero">Postcode</Form.Label>
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        value={values.zip}
                                                        name="zip"
                                                        placeholder="Postcode"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        error={errors.zip}
                                                        touched={touched.zip}
                                                        isInvalid={errors.zip && touched.zip}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.zip}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>                                                                                                                           
                                                                                       
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Map setCoordinates={this.setCoordinates} address={values.street1 + ' ' + (values.street2 ? values.street2 : '') + ' ' + values.suburb + ' ' + values.zip}/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={4} className="margin-top-10 margin-bottom-zero">
                                            <Form.Label><h5>Notes</h5></Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} xs={8} controlId="notes" className="margin-bottom-8">
                                            {/* <Form.Label className="med-font margin-bottom-zero">Notes</Form.Label> */}
                                            <Form.Control as="textarea" rows="3" 
                                                size="sm"
                                                placeholder="Notes" 
                                                name="notes"
                                                value={values.notes}
                                                onChange={handleChange} 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    {status && status.msg && 
                                    <Form.Row>
                                    <Form.Group>
                                        <Form.Label className="error-msg" dangerouslySetInnerHTML = {{__html:status.msg}}/> 
                                    </Form.Group>
                                    </Form.Row>
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    <Form.Row>                                
                                        <span className="d-inline-block">
                                        <Button variant="primary" type="submit" disabled={isSubmitting} size="sm">
                                        {isSubmitting ? 'Loading…' : 'Save'}
                                        </Button>
                                        </span>
                                        &nbsp;&nbsp;
                                        <span className="d-inline-block">
                                            {/* <Button variant="primary" onClick={() => this.props.navProp.changeClientPage(this.state.divisionId !== null ? 'locationList' : (this.props.id !== undefined && this.props.id.startsWith('Client') ? 'divisionList' : 'clientList'), this.state.divisionId !== null ? this.state.divisionId : (this.props.id !== undefined && this.props.id.startsWith('Client') ? this.props.id : null))} size="sm"> */}
                                            <Button as={Link} to={this.state.divisionId !== undefined ? `/clients/locationList/${this.state.divisionId}` : (this.state.clientId !== undefined ? `/clients/divisionList/${this.state.clientId}` : '/clients')} variant="primary" size="sm">
                                            Cancel
                                            </Button>
                                        </span>
                                        &nbsp;&nbsp;
                                        {this.state.locationId &&
                                        <DeleteSingleEntityButton {...this.props} entity='locations' entityId={this.state.locationId} postDeleteLocation={this.state.divisionId !== undefined ? `/clients/locationList/${this.state.divisionId}` : (this.state.clientId !== undefined ? `/clients/divisionList/${this.state.clientId}` : '/clients')}></DeleteSingleEntityButton>
                                        }
                                    </Form.Row>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Container>  
                  )}
                </Formik>
                 
            )
        }
        
    }
}