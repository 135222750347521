import React, { useState, useEffect } from 'react';
import {Button, Modal, Form, Col, Alert} from 'react-bootstrap';
import Map from './Map';
import { Auth, API } from "aws-amplify";
import moment from "moment";
import AddEditClient from '../AddEditClient';
import LoadingOverlay from 'react-loading-overlay';
import {authorizationHeader} from './Helper';
import { Formik } from 'formik';
import * as yup from 'yup';

function CreateNewLocationModal(props) {
    const [show, setShow] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [showMap, setShowMap] = useState(false);
    const [showClientForm, setShowClientForm] = useState(false);
    const [showDivisionForm, setShowDivisionForm] = useState(false);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [divisions, setDivisions] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    useEffect(() => {
        setShowMap(show);        
        if(show){
            fetch();
            

            if(props.isPickup) {
                // props.callBackFunctions.setFieldValue('pickuplocation', '');
                // props.callBackFunctions.setFieldValue('pickupstreet1', '');
                // props.callBackFunctions.setFieldValue('pickupstreet2', '');
                // props.callBackFunctions.setFieldValue('pickupcity', '');
                // props.callBackFunctions.setFieldValue('pickupsuburb', '');
                // props.callBackFunctions.setFieldValue('pickupzip', '');
            }
            else {
                props.callBackFunctions.setLocation('');
                props.callBackFunctions.setLocationItem(null);
                props.callBackFunctions.setFieldValue('location', '');
                props.callBackFunctions.setFieldValue('street1', '');
                props.callBackFunctions.setFieldValue('street2', '');
                props.callBackFunctions.setFieldValue('city', '');
                props.callBackFunctions.setFieldValue('suburb', '');
                props.callBackFunctions.setFieldValue('zip', '');
            }
            
        }
        else{
            setClients([]);
            setDivisions([]);
        }
    },[show])

    const fetch = async () => {
        setIsLoading(true);
        setShow(true);
        
        setError('');
        try{         
            const entityArray = ['clients','divisions'];
            const authHeader = await authorizationHeader();
            const entities = entityArray.map( async (entity, index) => {
                try{
                    let apiCall = '';
                    if(entity === 'clients')
                        apiCall = 'clients?ForAddJobPage=true';
                    else{
                        if(entity === 'divisions')
                            apiCall = 'divisions?ForAddJobPage=true';
                        else
                            apiCall = entity;
                    }
                    const res = await API.get('api', '/' + apiCall, authHeader);                
                    return (
                        {
                            entity: entity,
                            content: res
                        }
                    )
                }catch(err) {
                    if(err.toString().toLowerCase().indexOf('network error'))
                        setError('Network error. Please check internet connection then refresh the page.');
                    else
                        setError('Unexpected error encountered.');
                }
            });
            
            const resultEntities = await Promise.all(entities);
            if(props.selectedClient && props.selectedClient.length > 0) {
                setClients(resultEntities.filter(e => e.entity === 'clients')[0].content.clients.filter(c => c.ItemId === props.selectedClient));
            }
            else {
                setClients(resultEntities.filter(e => e.entity === 'clients')[0].content.clients);
            }

            if(props.selectedDivision && props.selectedDivision.length > 0) {
                setDivisions(resultEntities.filter(e => e.entity === 'divisions')[0].content.divisions.filter(d => d.Name === props.selectedDivision));
            }
            else {
                setDivisions(resultEntities.filter(e => e.entity === 'divisions')[0].content.divisions);
            }
        }catch(error) {
            if(error.toString().toLowerCase().indexOf('network error'))
                setError('Network error. Please check internet connection then refresh the page.');
            else
                setError('Unexpected error encountered.');
        }  
        setIsLoading(false);      
    }

    const getClients = () => {
        return clients.map((client, index) => {
            const { Name, ItemId } = client
            return (
                <option key={ItemId} value={ItemId} >{Name}</option>
            )
        })
    }

    const getDivisions = (values) => {
        if(values.client.length > 0) {
            if(values.division.length > 0) {
                return divisions.filter(d => d.ParentItemId === values.client && d.Name === values.division).map((division, index) => {
                    const { Name, ItemId } = division
                    return (
                        <option key={ItemId} value={Name} >{Name}</option>
                    )
                })
            }
            else {
                return divisions.filter(d => d.ParentItemId === values.client).map((division, index) => {
                    const { Name, ItemId } = division
                    return (
                        <option key={ItemId} value={Name} >{Name}</option>
                    )
                })
            }
        }else{
            return divisions.map((division, index) => {
                const { Name, ItemId } = division
                return (
                    <option key={ItemId} value={Name} >{Name}</option>
                )
            })
        }
    }

    const setCoordinates = (latitude, longitude) => {
        console.log('latlong', latitude);
        setLatitude(latitude);
        setLongitude(longitude);
    }

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const createLocation = async(values, actions) => {
        try{
            if(latitude !== '' && longitude !== '')
            {
                // if(props.existingLocations.filter(l => l.ParentItemId === values.division && l.Name.trim().toLowerCase() === values.location.trim().toLowerCase()).length === 0)
                // {
                    
                // }
                // else{
                //     actions.setStatus({msg: 'Location name already exists for the selected division.'});
                //     actions.setSubmitting(false);
                // }
                const myInit = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                    body: {
                        locations: [
                            {
                                ParentItemId: divisions.filter(d => d.ParentItemId === values.client && d.Name === values.division)[0].ItemId,
                                Name: values.location,   
                                Division: divisions.filter(d => d.ParentItemId === values.client && d.Name === values.division)[0].Name,                     
                                Street1: values.street1,
                                Street2: values.street2,
                                Suburb: values.suburb,
                                State: values.state,
                                Zip: values.postcode,
                                Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                            }
                        ]
                        }
                };
                //console.log(JSON.stringify(myInit));
                const result = await API.post("api", "/locations", myInit);
                if(result.result === 'success')
                {
                    props.callBackFunctions.setLocation(result.locations[0].Name);
                    const newLocation = result.locations.map(row => {
                        return({
                            name: row.Name,
                            id: row.ItemId,
                            division: row.Division,
                            divisionId: row.ParentItemId,
                            operatorId: row.OperatorId,
                            street1: row.Street1,
                            street2: row.Street2,
                            city: row.Suburb,
                            state: row.State,
                            suburb: row.Suburb,
                            zip: row.Zip
                        })                        
                    })[0];
                    props.callBackFunctions.setLocationItem(newLocation);
                    props.callBackFunctions.setFieldValue('client', values.client);
                    if(props.isPickup) {
                        props.callBackFunctions.setFieldValue('pickuplocation', newLocation.name);
                        props.callBackFunctions.setFieldValue('division', newLocation.division);
                        props.callBackFunctions.setFieldValue('pickupstreet1', newLocation.street1);
                        props.callBackFunctions.setFieldValue('pickupstreet2', newLocation.street2);
                        props.callBackFunctions.setFieldValue('pickupcity', newLocation.city);
                        props.callBackFunctions.setFieldValue('pickupsuburb', newLocation.suburb);
                        props.callBackFunctions.setFieldValue('pickupzip', newLocation.zip);
                    }
                    else {
                        props.callBackFunctions.setFieldValue('location', newLocation.name);
                        props.callBackFunctions.setFieldValue('division', newLocation.division);
                        props.callBackFunctions.setFieldValue('street1', newLocation.street1);
                        props.callBackFunctions.setFieldValue('street2', newLocation.street2);
                        props.callBackFunctions.setFieldValue('city', newLocation.city);
                        props.callBackFunctions.setFieldValue('suburb', newLocation.suburb);
                        props.callBackFunctions.setFieldValue('zip', newLocation.zip);
                    }
                    actions.setSubmitting(false);
                    setShow(false);
                    if(props.callBackFunctions.setCoordinates)
                    {
                        await sleep(2000);
                        props.callBackFunctions.setCoordinates(latitude,longitude);
                    }
                    actions.setFieldValue('location', '');
                    actions.setFieldValue('division', '');
                    actions.setFieldValue('street1', '');
                    actions.setFieldValue('street2', '');
                    actions.setFieldValue('suburb', '');
                    actions.setFieldValue('state', '');
                    actions.setFieldValue('postcode', '');
                }                    
                else{
                    actions.setStatus({msg: result.errors[0]});
                    actions.setSubmitting(false);
                }
            }
            else
            {
                actions.setStatus({msg: 'The address provided is not valid.'});
                actions.setSubmitting(false);
            }
        }catch(error)
        {
            console.log('error', error.message);
            actions.setStatus({msg: error.message});
            actions.setSubmitting(false);
        }
    }

    const schema = yup.object().shape({
        client: yup.string()
            .required('Required'),
        division: yup.string()
            .required('Required'),
        location: yup.string()
            .required('Required'),
        suburb: yup.string()
            .required('Required'),
        postcode: yup.string()
            .required('Required'),
    });

    return(
        <React.Fragment>            
            <Formik
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                await createLocation(values, actions);
            }}            
            initialValues={{
                location: '',
                client: selectedClient,
                division: selectedDivision,
                street1: '',
                street2: '',
                suburb: '',
                postcode: '',
                state: ''
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
              status,
              setFieldValue
            }) => (
            <React.Fragment>
                <Button variant="success" size="sm" onClick={() => {
                    setShow(true); 
                    setSelectedClient(props.selectedClient); 
                    setSelectedDivision(props.selectedDivision);
                    setFieldValue('client', props.selectedClient);
                    setFieldValue('division', props.selectedDivision);}}>
                    <i className="fas fa-plus" style={{ fontSize: '1em' }} />
                </Button>
                <ModalContainer size={'xl'} show={show} setShow={setShow} blurred={showClientForm || showDivisionForm}>
                    <Form onSubmit={handleSubmit} >
                    <Form.Row>
                        <Form.Group as={Col} xs={4} controlId="client" className="margin-bottom-8">
                            <Form.Row>
                                <Form.Group as={Col} xs={12} controlId="client" className="margin-bottom-8">
                                    <Form.Label className="med-font margin-bottom-zero">Client</Form.Label>
                                    <Form.Row>
                                        <Form.Group className="margin-bottom-zero" as={Col} xs={12}>
                                            <Form.Control as="select"
                                            disabled={isLoading}
                                            size="sm"
                                            type="text"                                        
                                            value={values.client}
                                            onChange={(e) => {
                                                // setSelectedClient(e.target.value);
                                                setFieldValue('client', e.target.value);
                                                setFieldValue('division', '');
                                            }}
                                            placeholder={isLoading ? "Loading..." : "Select from existing Clients"}
                                            isInvalid={errors.client && touched.client}
                                            >
                                                <option value="">{isLoading ? "Loading..." : "Select existing Client"}</option>
                                                {getClients()}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.client}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {/* <Form.Group className="margin-bottom-zero" as={Col}>
                                            <Button variant="success" size="sm" onClick={() => setShowClientForm(true)}>
                                                <i className="fas fa-plus" style={{ fontSize: '1em' }} />
                                            </Button>
                                        </Form.Group> */}
                                    </Form.Row>                                    
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} controlId="division" className="margin-bottom-8">
                                    <Form.Label className="med-font margin-bottom-zero">Division</Form.Label>
                                    <Form.Row>
                                        <Form.Group className="margin-bottom-zero" as={Col} xs={12}>
                                            <Form.Control as="select"
                                            disabled={isLoading || values.client.length === 0}
                                            size="sm"
                                            type="text"
                                            value={values.division}
                                            onChange={(e) => {
                                                // setSelectedDivision(e.target.value);
                                                setFieldValue('division', e.target.value);
                                            }}
                                            placeholder="Select from existing Divisions"
                                            isInvalid={errors.division && touched.division}
                                            >
                                                <option value="">{isLoading ? 'Loading...' : (values.client.length === 0 ? 'Select client first' : 'Select existing division')}</option>
                                                {getDivisions(values)}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.division}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {/* <Form.Group className="margin-bottom-zero" as={Col}>
                                            <Button variant="success" size="sm">
                                                <i className="fas fa-plus" style={{ fontSize: '1em' }} />
                                            </Button>
                                        </Form.Group> */}
                                    </Form.Row>                                    
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className="margin-bottom-8" as={Col} xs={12}>
                                    <Form.Label className="med-font margin-bottom-zero">Location Name</Form.Label>
                                    <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="Location Name"
                                    name="location"
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.location && touched.location}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.location}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>  
                            <Form.Row>
                                <Form.Group className="margin-bottom-8" as={Col} xs={12}>
                                    <Form.Label className="med-font margin-bottom-zero">Address 1</Form.Label>
                                    <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="Address 1"
                                    name="street1"
                                    value={values.street1}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.street1 && touched.street1}
                                    />
                                </Form.Group>
                            </Form.Row>   
                            <Form.Row>
                                <Form.Group className="margin-bottom-8" as={Col} xs={12}>
                                    <Form.Label className="med-font margin-bottom-zero">Address 2</Form.Label>
                                    <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="Address 2"
                                    name="street2"
                                    value={values.street2}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.street2 && touched.street2}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className="margin-bottom-8" as={Col} xs={6}>
                                    <Form.Label className="med-font margin-bottom-zero">Suburb</Form.Label>
                                    <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="Suburb"
                                    name="suburb"
                                    value={values.suburb}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.suburb && touched.suburb}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.suburb}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="margin-bottom-8" as={Col} xs={6}>
                                    <Form.Label className="med-font margin-bottom-zero">Postcode</Form.Label>
                                    <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="Postcode"
                                    name="postcode"
                                    value={values.postcode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.postcode && touched.postcode}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.postcode}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row> 
                            <Form.Row>
                                <Form.Group className="margin-bottom-8" as={Col} xs={12}>
                                    <Form.Label className="med-font margin-bottom-zero">State</Form.Label>
                                    <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="State"
                                    name="state"
                                    value={values.state}
                                    onChange={handleChange}
                                    />
                                </Form.Group>
                            </Form.Row>                                                
                        </Form.Group>   
                        <Form.Group as={Col} controlId="client" className="margin-bottom-8">
                            {showMap === true && showClientForm === false && showDivisionForm === false &&
                                <Map setCoordinates={setCoordinates} address={values.street1 + ' ' + (values.street2 ? values.street2 : '') + ' ' + values.suburb + ' ' + values.postcode }/>
                            }
                        </Form.Group>             
                    </Form.Row>
                    {status && status.msg && 
                    <Form.Row>
                        <Form.Group>
                            <Form.Label className="error-msg" dangerouslySetInnerHTML = {{__html:status.msg}}/> 
                        </Form.Group>
                    </Form.Row>
                    }  
                    <Form.Row>                                                            
                        <Button variant="primary" type="submit" className="mr-sm-2" disabled={isSubmitting} size="sm">
                        {isSubmitting ? 'Creating…' : 'Create'}
                        </Button>

                        <Button variant="primary" size="sm" onClick={() => setShow(false)}>
                        Cancel
                        </Button>
                    </Form.Row> 
                    </Form>                           
                </ModalContainer>
            </React.Fragment>
            )}
            </Formik>
            <ModalContainer title='Create New Client' size={'lg'} show={showClientForm} setShow={setShowClientForm}>
                <AddEditClient miniVersion={true} />
            </ModalContainer>
        </React.Fragment>
    )
}

function ModalContainer(props) {

    return (
        <Modal
        size={props.size ? props.size : 'md'}
        dialogClassName={props.size && props.size === "xl" ? "modal-xl" : ""}
        show={props.show}
        onHide={() => props.setShow(false)}
        backdrop= "static"
        >
            <LoadingOverlay
            active={props.blurred ? props.blurred : false}
            spinner={false}
            text=''
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {props.title ? props.title : 'Create New Location'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    {props.children}                             
                </Modal.Body>  
            </LoadingOverlay>                  
        </Modal>
    )
}

export default CreateNewLocationModal