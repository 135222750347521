import React, { useState, useEffect } from 'react';
import { Auth, API } from "aws-amplify";
import {Card, Container, Form, Button, Alert, Col, ProgressBar} from 'react-bootstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import axios from 'axios';
import moment from 'moment';
import Download from './utility/Download';
import Papa from 'papaparse';
import {geoSearch, authorizationHeader, getGUID} from './utility/Helper';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver';
import PostcodeDivisionCheck from './utility/PostcodeDivisionCheck';

function Import() {
    const [isProcessing, setIsProcessing] = useState(false);
    const [templateUrl, setTemplateUrl] = useState('');
    const [ignoreFirstLine, setIgnoreFirstLine] = useState(true);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [previewedFile, setPreviewedFile] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [apiError, setApiError] = useState(undefined);
    const [jobs, setJobs] = useState(undefined);
    const [savedJobs, setSavedJobs] = useState([]);
    const [invalidJobs, setInvalidJobs] = useState([]);
    const [fileRefreshToken, setFileRefreshToken] = useState(Date.now());
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        console.log('useEffect selectedFile');
        if(selectedFile)
        {
            if(selectedFile.name.toLowerCase().endsWith('.csv'))
                setError(undefined);
            else
                setError('The file is not a .csv format');
        }else
            setError(undefined);
        setApiError(undefined);
    },[selectedFile])

    const downloadTemplate = async (e) => {
        e.preventDefault()
        const myInit = {
            headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            },
        };
        const res = await API.get('api', '/uploads?type=download&File=SampleImport.csv&folder=Sample', myInit);
        if(res['result'] === 'success')
            setTemplateUrl(res['url']);
    }

    const onChangeHandler = event => {
        setSelectedFile(event.target.files[0]);
        setJobs(undefined);
    }

    const infoRow = () => {
        if(error)
        {
            return(
                <Form.Row>
                    <Form.Group>
                        <Alert variant='danger'>
                            {error}
                        </Alert>
                    </Form.Group>                                    
                </Form.Row>
            )
        }
    }

    const getNextDayDeliveryDate = () => {
        var today = moment(new Date().toUTCString()).tz('Australia/Melbourne')
        var day = today.day();

        if(day === 5) {
            today.add(3, 'days');
        }
        else if(day === 6) {
            today.add(2, 'days');
        }
        else {
            today.add(1, 'days');
        }


        return new Date(today.year(), today.month(), today.date());
    }

    const processError = () => {
        if(apiError)
        {
            return(
                <Form.Row>
                    <Form.Group>
                        <Alert variant='danger'>
                            {apiError}
                        </Alert>
                    </Form.Group>                                    
                </Form.Row>
            )
        }
    }

    const processImport = async () => {
        const session = await Auth.currentSession();
        setIsProcessing(true);
        setApiError(undefined);
        if(jobs)
        {
            let processError = false;
            const totalProgress = jobs.length + 6;
            let currentProgress = 0;
            let status;
            let drivers;
            let divisions;
            let locations;
            let existingJobs;
            let servicablepostcodes;
            try{
                let dateFrom = '';
                let dateTo = '';
                let divisionString = '';
                let jobFilter = '';
                jobs.forEach((j,index) => {
                    if(j.Division && j.Division.length > 0 && ('|' + divisionString + '|').indexOf('|' + j.Division.trim().toUpperCase() + '|') < 0)
                        divisionString = divisionString === '' ? divisionString + j.Division.trim().toUpperCase() : divisionString + '|' + j.Division.trim().toUpperCase();
                    let DeliveryDate = j.DeliveryDate;
                    if(DeliveryDate === null || DeliveryDate === "") {
                        DeliveryDate = getNextDayDeliveryDate();
                    }
                    if(moment(DeliveryDate).format("YYYY-MM-DD").toLowerCase() !== 'invalid date')
                    {
                        if(dateFrom === '')
                            dateFrom = moment(DeliveryDate).format("YYYY-MM-DD");
                        if(dateTo === '')
                            dateTo = moment(DeliveryDate).format("YYYY-MM-DD");
                        if(moment(DeliveryDate).isBefore(moment(dateFrom)))
                            dateFrom = moment(DeliveryDate).format("YYYY-MM-DD");
                        if(moment(DeliveryDate).isAfter(moment(dateTo)))
                            dateTo = moment(DeliveryDate).format("YYYY-MM-DD");
                    }
                    else {
                        processError = true;
                    }
                });
                if(dateFrom !== '' && dateTo !== '')
                    jobFilter = '?Where=DeliveryDate~between~' + dateFrom + '|' + dateTo + ' 23:59:59&IncludeUnallocated=true';
                if(divisionString !== '')
                    jobFilter = jobFilter + (jobFilter === '' ? '?' : '&') + 'Condition=Division~in~' + divisionString;
                console.log('jobFilter', jobFilter);

                const entityArray = ['status','accounts','divisions','locations','jobs', 'servicablepostcodes'];
                const authHeader = await authorizationHeader();
                const entities = entityArray.map( async (entity, index) => {
                    try{
                        let res;
                        if(entity === 'servicablepostcodes')
                            res = await API.get('api', '/locations/servicable/postcodes', authHeader);
                        else if(entity === 'accounts')
                            res = await API.get('api', '/accounts?AccountType=Driver&Status=active', authHeader);
                        else{
                            if(entity === 'status') {
                                res = await API.get('api', '/status?IncludeUnallocated=true', authHeader);
                                // res.status = res.status.filter(ss => ss.IsActive);
                            }                                
                            else
                            {
                                if(entity === 'jobs')
                                    res = await API.get('api', '/jobs' + jobFilter, authHeader);
                                else
                                {
                                    if(entity === 'locations')
                                        res = await API.get('api', '/locations' + (divisionString !== '' ? '?Condition=Division~in~' + divisionString : ''), authHeader);
                                    else
                                        res = await API.get('api', '/' + entity, authHeader);
                                }
                            }
                        }                        
                        console.log('entity',entity);
                        currentProgress = currentProgress + Math.round(1 / totalProgress * 100);
                        setProgress(currentProgress);
                        return (
                            {
                                entity: entity,
                                content: res
                            }
                        )
                    }catch(err) {
                        //this.setState(() => { throw err; })
                        processError = true;
                        console.log('error', err);
                        if(err.toString().toLowerCase().indexOf('network error'))
                            setApiError('Network error. Please check internet connection.');
                        else
                            setApiError('Unexpected error encountered.');
                    }
                });
                
                const resultEntities = await Promise.all(entities);
                console.log('resultEntities',resultEntities);
                status = resultEntities.filter(e => e.entity === 'status')[0].content.status;
                drivers = resultEntities.filter(e => e.entity === 'accounts')[0].content.accounts;
                divisions = resultEntities.filter(e => e.entity === 'divisions')[0].content.divisions;
                locations = resultEntities.filter(e => e.entity === 'locations')[0].content.locations;
                existingJobs = resultEntities.filter(e => e.entity === 'jobs')[0].content.jobs;
                servicablepostcodes = resultEntities.filter(e => e.entity === 'servicablepostcodes')[0].content.postcodes;
                console.log('assigned entities to variables');
            }catch(error) {
                //this.setState(() => { throw err; })
                processError = true;
                console.log('error', error);
                // if(err.toString().toLowerCase().indexOf('network error'))
                //     setApiError('Network error. Please check internet connection and then refresh the page');
                // else
                //     setApiError('Unexpected error encountered.');
            }            
            
            if(processError === false)
            {
                let validatedLocations = [];
                const validatedJobs = jobs.map(async (j,index) => {
                    let isValid = true;
                    let errors = [];
                    //Latitude, Longitude & State
                    const address = (j.Street1 ? j.Street1 : '') + ' ' + (j.Street2 ? j.Street2 : '') + ' ' + (j.Suburb ? j.Suburb : '') + ' ' + (j.Zip ? j.Zip : '');
                    const coordinates = await geoSearch(address);
                    //DeliveryDate
                    let DeliveryDate = j.DeliveryDate;
                    if(DeliveryDate == null || DeliveryDate == "") {
                        DeliveryDate = getNextDayDeliveryDate();
                    }
                    if(moment(DeliveryDate).format("YYYY-MM-DD").toLowerCase() === 'invalid date')
                    {
                        isValid = false;
                        errors.push('Invalid Date');
                    }
                    else
                        DeliveryDate = moment(DeliveryDate).format("YYYY-MM-DD") + ' 00:00:00';
                    //ConsignmentNumber
                    let ConsignmentNumber = '';
                    if(j.ConsignmentNumber === undefined || j.ConsignmentNumber.trim() === '')
                    {
                        isValid = false;
                        errors.push('No Consignment Number');
                    }else{
                        ConsignmentNumber = j.ConsignmentNumber;
                    }
                    //Check if duplicate
                    if(existingJobs.filter(x => x.ConsignmentNumber.trim() === (j.ConsignmentNumber ? j.ConsignmentNumber.trim() : '') && x.Division.trim().toUpperCase() === (j.Division ? j.Division.trim().toUpperCase() : '') && moment(DeliveryDate).format("YYYY-MM-DD") === moment(x.DeliveryDate).format("YYYY-MM-DD")).length > 0)
                    {
                        isValid = false;
                        errors.push('Duplicate job');
                    }
                    //Check if has duplicate in the same file
                    if(jobs.filter(x => x.ConsignmentNumber.trim() === (j.ConsignmentNumber ? j.ConsignmentNumber.trim() : '') && x.Division.trim().toUpperCase() === (j.Division ? j.Division.trim().toUpperCase() : '') && moment(DeliveryDate).format("YYYY-MM-DD") === moment(x.DeliveryDate).format("YYYY-MM-DD")).length > 1)
                    {
                        isValid = false;
                        errors.push('Has duplicate record in same file');
                    }
                    //Cartons
                    let Cartons;
                    if (j.Cartons === undefined || isNaN(j.Cartons) || j.Cartons.trim() === '')
                    {
                        Cartons = j.Cartons;
                        isValid = false;
                        errors.push('Invalid Cartons');
                    }else{
                        Cartons = parseInt(j.Cartons);
                    }
                    //Division, driver & Location
                    let Division = '';
                    let ParentItemId = '';
                    let DriverId = '';
                    let DriverName = '';
                    let LocationName = '';
                    let DeliveryLocationId = '';
                    let Suburb = '';
                    if(j.Suburb === undefined || j.Suburb.trim() === '')
                    {
                        isValid = false;
                        errors.push('No Suburb');
                    }else{
                        Suburb = j.Suburb.trim().replace(/  +/g, ' ');
                    }
                    if(j.LocationName === undefined || j.LocationName.trim() === '')
                    {
                        isValid = false;
                        errors.push('No Location Name');
                    }else{
                        LocationName = j.LocationName.trim().replace(/  +/g, ' ');
                    }
                    if(j.Zip === undefined || j.Zip.trim() === '' || isNaN(j.Zip))
                    {
                        isValid = false;
                        errors.push('Invalid Postcode');
                    }else{
                        let filteredDrivers = drivers.filter(d => (',' + (d.TerritoryPostcodes ? d.TerritoryPostcodes : '').replace(/\s+/g, '') + ',').indexOf(',' + j.Zip.replace(/\s+/g, '') + ',') > -1 );
                        console.log('filteredDrivers', filteredDrivers);
                        if(filteredDrivers.length > 0)
                        {
                            const driverIndex = Math.round(Math.random() * (filteredDrivers.length - 1));
                            console.log('driverIndex',driverIndex);
                            DriverId = filteredDrivers[driverIndex].ItemId;
                            DriverName = filteredDrivers[driverIndex].FullName;
                        }else{
                            if(coordinates.valid && coordinates.state && coordinates.state.length > 0)
                            {
                                filteredDrivers = drivers.filter(d => d.StateManager === true && d.State && d.State.toLowerCase().trim() === coordinates.state.toLowerCase().trim());
                                if(filteredDrivers.length > 0)
                                {
                                    const driverIndex = Math.round(Math.random() * (filteredDrivers.length - 1));
                                    console.log('driverIndex',driverIndex);
                                    DriverId = filteredDrivers[driverIndex].ItemId;
                                    DriverName = filteredDrivers[driverIndex].FullName;
                                }
                            }
                        }
                        if(servicablepostcodes.filter(s => s.PostCode.trim().replace('\r','') === j.Zip.trim() && s.Suburb.trim().toLowerCase() === j.Suburb.trim().toLowerCase()).length === 0 && session.idToken.payload['cognito:groups'][0].toLowerCase() === 'companyadministrator')
                        {
                            isValid = false;
                            if(servicablepostcodes.filter(s => s.PostCode.trim().replace('\r','') === j.Zip.trim()).length > 0)
                            {
                                const suburbs = servicablepostcodes.filter(s => s.PostCode.trim().replace('\r','') === j.Zip.trim());
                                errors.push('Apologies! postcode is not servicable by Mediport. Alternatively, you may use the suburbs '+ suburbs.map(m=> m.Suburb).join(',') +' for this postcode.')
                            }
                            else
                                errors.push('Apologies! postcode is not servicable by Mediport.');
                        }
                        // else{
                        //     isValid = false;
                        //     errors.push('No driver found for the postcode');
                        // }
                    }
                    if(j.Division)
                    {
                        if(divisions.filter(d => j.Division.toUpperCase().trim() === d.Name.toUpperCase().trim()).length > 0){
                            Division = j.Division.toUpperCase().trim();
                            ParentItemId = divisions.filter(d => j.Division.toUpperCase().trim() === d.Name.toUpperCase().trim())[0].ItemId;
                            if(isValid){
                                let location = undefined;
                                if(locations && locations.filter(l => l.Name.toUpperCase().trim().replace(/  +/g, ' ') === j.LocationName.toUpperCase().trim().replace(/  +/g, ' ') && l.ParentItemId === ParentItemId).length > 0)
                                {
                                    console.log('has existing location');
                                    location = locations.filter(l => l.Name.toUpperCase().trim().replace(/  +/g, ' ') === j.LocationName.toUpperCase().trim().replace(/  +/g, ' ') && l.ParentItemId === ParentItemId)[0];
                                }
                                DeliveryLocationId = location ? location.ItemId : 'Location-' + getGUID();
                                if(validatedLocations.filter(l => l.Name.toUpperCase().trim().replace(/  +/g, ' ') === j.LocationName.toUpperCase().trim().replace(/  +/g, ' ') && l.ParentItemId === ParentItemId).length === 0)
                                {
                                    validatedLocations.push({
                                        ItemId: DeliveryLocationId,
                                        Name: location ? location.Name.replace(/  +/g, ' ') : j.LocationName.replace(/  +/g, ' '),
                                        ParentItemId: ParentItemId,
                                        Division: Division,
                                        Street1: j.Street1 ? j.Street1 : undefined,
                                        Street2: j.Street2 ? j.Street2 : undefined,
                                        Suburb: j.Suburb ? j.Suburb : undefined,
                                        State: coordinates.valid ? coordinates.state : undefined,
                                        Zip: j.Zip ? j.Zip : undefined,
                                        FirstName: '',
                                        LastName: '',
                                        Email: '',
                                        MobileNumber: location ? location.MobileNumber : '',
                                        Landline: '',
                                        FaxNumber: '',
                                        Notes: '',
                                        Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                                    });
                                }
                            }                        
                        }
                        else{
                            Division = j.Division;
                            isValid = false;
                            errors.push('Division does not exist');
                        }
                    }else{
                        isValid = false;
                        errors.push('Invalid Division');
                    }
                    //Status
                    let StatusId = '';
                    let Status = '';
                    let StatusGroup = '';
                    if(status.length > 0)
                    {
                        if(DriverId !== '')
                        {
                            const NewDeliveryStatus = status.filter(s => s.Label.toLowerCase() === "new delivery");
                            if(NewDeliveryStatus.length > 0)
                            {
                                StatusId = NewDeliveryStatus[0].ItemId;
                                Status = NewDeliveryStatus[0].Label;
                                StatusGroup = NewDeliveryStatus[0].Type.toString();
                            }else{
                                isValid = false;
                                errors.push('No New Delivery status setup');
                            }
                        }else{
                            const UnallocatedStatus = status.filter(s => s.Label.toLowerCase() === "unallocated");
                            if(UnallocatedStatus.length > 0)
                            {
                                StatusId = UnallocatedStatus[0].ItemId;
                                Status = UnallocatedStatus[0].Label;
                                StatusGroup = UnallocatedStatus[0].Type.toString();
                            }else{
                                isValid = false;
                                errors.push('No Unallocated status setup');
                            }
                        }
                    }else{
                        isValid = false;
                        errors.push('No Status setup yet');
                    }
                    //DeliveryType if there is
                    let DeliveryType = undefined;
                    if(j.DeliveryType){
                        if(j.DeliveryType.toLowerCase().replace(/ /g, '').trim() === 'nextday')
                            DeliveryType = 'Next Day';
                        else{
                            if(j.DeliveryType.toLowerCase().replace(/ /g, '').trim() !== 'sameday' && j.DeliveryType.toLowerCase().replace(/ /g, '').trim() !== ''){
                                isValid = false;
                                errors.push('Invalid DeliveryType');
                                DeliveryType = j.DeliveryType;
                            }                                
                        }                        
                    }
                    //Cubic Capacity if there is
                    let CubicCapacityMetresCubed = undefined;
                    if(j.CubicCapacityMetresCubed && j.CubicCapacityMetresCubed.trim() !== ''){
                        if (isNaN(j.CubicCapacityMetresCubed)){
                            isValid = false;
                            errors.push('Invalid Cubic Capacity Metres Cubed');
                        }
                        CubicCapacityMetresCubed = j.CubicCapacityMetresCubed;
                    }

                    let JobType = undefined;
                    if(j.JobType && j.JobType.trim() !== '') {
                        if (j.JobType === "Delivery Carton" || j.JobType === "Pickup Carton"
                        || j.JobType === "Delivery Carton Ambient" || j.JobType == "Pickup Carton Ambient"
                        || j.JobType === "Delivery Pallet" || j.JobType === "Pickup Pallet"
                        || j.JobType === "Credo Delivery" || j.JobType === "Credo Pickup"
                        || j.JobType === "Delivery Pallet Ambient" || j.JobType === "Pickup Pallet Ambient"
                        || j.JobType === "Return to Sender") {
                            JobType = j.JobType
                        }
                        else {
                            isValid = false;
                            errors.push('Invalid Job Type');
                        }
                    }
                    else {
                        JobType = 'Delivery Carton'
                    }

                    currentProgress = currentProgress + Math.round(1 / totalProgress * 100);
                    setProgress(currentProgress > 100 ? 100 : currentProgress);
                    let manifestFile = '';
                    try{
                        manifestFile = previewedFile.name;
                    }catch(error){
                        console.log('error', error);
                    }
                    return({
                        Id: index,
                        Name: LocationName,
                        LocationName: j.LocationName ? j.LocationName : '',
                        ConsignmentNumber: ConsignmentNumber,
                        JobType: JobType,
                        DeliveryLocationId: DeliveryLocationId,
                        Latitude: coordinates.valid ? coordinates.y : undefined,
                        Longitude: coordinates.valid ? coordinates.x : undefined,
                        State: coordinates.valid ? coordinates.state : undefined,
                        Street1: j.Street1 ? j.Street1 : '',
                        Street2: j.Street2 ? j.Street2 : '',
                        Suburb: isValid ? Suburb : (j.Suburb ? j.Suburb : ''),
                        Zip: j.Zip ? j.Zip : '',
                        DeliveryDate: isValid ? DeliveryDate : (j.DeliveryDate ? j.DeliveryDate : ''),
                        HasDeliveryTime: false,
                        DriverId: DriverId,
                        DriverName: DriverName,
                        Cartons: Cartons,
                        PoR: j.PoR ? j.PoR.trim() : '',
                        ClientCode: j.ClientCode ? j.ClientCode.trim() : '',
                        ReferenceNumbers: j.ReferenceNumbers ? j.ReferenceNumbers.trim() : '',
                        Notes: j.Notes ? j.Notes.trim() : '',
                        Division: isValid ? Division : (j.Division ? j.Division : ''),
                        ParentItemId: ParentItemId,
                        StatusId: StatusId,
                        Status: Status,
                        StatusGroup: StatusGroup,
                        Created: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                        Modified: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                        SMS: false,
                        PointToPoint: false,
                        RateOverride: 0,
                        ManifestFile: manifestFile,
                        DeliveryType: DeliveryType,
                        DeliveryConsignmentIds: j.DeliveryConsignmentIds,
                        CubicCapacityMetresCubed: CubicCapacityMetresCubed,
                        IsInterstateDelivery: CubicCapacityMetresCubed ? true : false,
                        CreatedUTCValid: true,
                        LocationMobileNumber: j.LocationMobileNumber,
                        IsValid: isValid,
                        Errors: errors,
                    })
                });
                const validatedJobResults = await Promise.all(validatedJobs);
                console.log(validatedJobResults);
                console.log(validatedLocations);
                //Save or Update addresses
                const myInitLocations = {
                    headers: {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    },
                    body: {
                        locations: validatedLocations
                        }
                };
                const savedLocations = await API.post("api", "/locations", myInitLocations);
                console.log('savedLocations',savedLocations);
                currentProgress = currentProgress + Math.round(1 / totalProgress * 100);
                setProgress(currentProgress > 100 ? 100 : currentProgress);
                //Save valid jobs
                if(validatedJobResults.filter(j => j.IsValid === true).length > 0)
                {
                    const myInitJobs = {
                        headers: {
                            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                        },
                        body: {
                            jobs: validatedJobResults.filter(j => j.IsValid === true)
                            }
                    };
                    const savedJobsResult = await API.post("api", "/jobs", myInitJobs);
                    setSavedJobs(savedJobsResult.jobs);
                }
                currentProgress = currentProgress + Math.round(1 / totalProgress * 100);
                setProgress(currentProgress > 100 ? 100 : currentProgress);
                //await sleep(2000);
                //Upload file to s3
                if(previewedFile)
                {
                    const uploadHeader = await authorizationHeader();
                    const preSignedUrl = await API.get("api", "/imports/jobs/" + previewedFile.name.replace(/[^a-zA-Z0-9.]/g, ''), uploadHeader);
                    await axios({ method: 'put', url: preSignedUrl.url, data: previewedFile});
                }
                setInvalidJobs(validatedJobResults.filter(j => j.IsValid === false));
                setJobs(undefined);
                console.log('processImport end');
            }            
        }
        setIsProcessing(false);
    }

    const previewUpload = () => {
        setPreviewedFile(selectedFile);
        Papa.parse(selectedFile, {
            complete: function(results) {
                console.log(results);
                const previewJobs = results.data.filter((v,index) => index > (ignoreFirstLine ? 0 : -1)).map((d, index) => {
                    return(
                        {
                            Id: index,
                            DeliveryDate: (d[0] != null && d[0] != "") ? d[0] : moment(getNextDayDeliveryDate()).format("YYYY-MM-DD"),
                            ConsignmentNumber: d[1],
                            JobType: d[2] ? d[2] : 'Delivery Carton',
                            LocationName: d[3],
                            Street1: d[4],
                            Street2: d[5],
                            Suburb: d[6],
                            Zip: d[7],
                            Notes: d[8],
                            Division: d[9],
                            Cartons: d[10],
                            DeliveryType: d[11] ? d[11] : undefined,
                            DeliveryConsignmentIds: d[12] ? d[12] : undefined,
                            CubicCapacityMetresCubed: d[13] ? d[13] : undefined,
                            LocationMobileNumber: d[14] ? d[14] : undefined,
                            ClientCode: d[15] ? d[15] : undefined,
                            ReferenceNumbers: d[16] ? d[16] : undefined,
                            PoR: d[17] ? d[17] : undefined
                        }
                    )
                });
                setJobs(previewJobs);
                setProgress(0);
                setInvalidJobs([]);
                setSavedJobs([]);
                setSelectedFile(undefined);
                setFileRefreshToken(Date.now);
            },
            skipEmptyLines: true
        });
    }

    const fieldExists = (jobs, field) => {
        let exists = false;
        try{
            jobs.forEach(j => {
                if(j[field])
                    exists = true;
            });
        }catch(error) {
            exists = false;
            console.log('error', error);
        }
        return exists;
    }

    const valueFormatter = (cell, row) => {
        return <span className="small-font">{cell}</span>;
    }

    const errorFormatter = (cell, row) => {
        let errors = '';
        if(cell){
            cell.map(e => {
                if(errors === '')
                    errors = e;
                else{
                    errors = errors + '.\n' + e;
                }
            });
        }        
        return <span className="small-font error-msg">{errors}</span>;
    }

    const jobsDisplay = () => {
        if(jobs || invalidJobs.length > 0)
        {
            const savedJobsCount = savedJobs.length;
            const invalidJobsCount = invalidJobs.length;
            return(
                <React.Fragment>
                    {jobs &&
                    <Form.Row>
                        <Form.Group as={Col} xs={12} className="margin-top-20 margin-bottom-zero">
                            <h4>Confirm Jobs Import</h4>
                        </Form.Group>
                    </Form.Row>
                    }
                    {invalidJobsCount > 0 && savedJobsCount > 0 &&
                    <Form.Row>
                        <Form.Group as={Col} xs={12} className="margin-bottom-zero">
                            <Alert variant='success'>
                            {savedJobsCount + ' record/s were imported successfully!'}
                            </Alert>
                        </Form.Group>
                        <Form.Group as={Col} xs={12} className="margin-bottom-zero">
                            <Alert variant='danger'>
                            {'The following ' + invalidJobsCount + ' record/s were not imported successfully. Please see errors below and download these invalid records for re-uploading.'}
                            </Alert>
                        </Form.Group> 
                    </Form.Row>
                    }
                    {invalidJobsCount > 0 && savedJobsCount === 0 &&
                    <Form.Row>
                        <Form.Group as={Col} xs={12} className="margin-bottom-zero">
                            <Alert variant='danger'>
                            {'All records were not imported successfully. Please see below errors.'}
                            </Alert>
                        </Form.Group> 
                    </Form.Row>
                    }
                    <BootstrapTable data={jobs !== undefined ? jobs.slice(0, 5) : invalidJobs}>    
                        <TableHeaderColumn dataField='Id' isKey hidden>ItemId</TableHeaderColumn>
                        <TableHeaderColumn dataField='DeliveryDate' dataFormat={valueFormatter} width='90' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false} ><span className="small-header-font">Delivery Date</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='ConsignmentNumber' dataFormat={valueFormatter} thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Consignment Number</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='JobType' dataFormat={valueFormatter} thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Job Type</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='LocationName' dataFormat={valueFormatter} thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Location</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Street1' dataFormat={valueFormatter} thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Address 1</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Street2' dataFormat={valueFormatter} thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Address 2</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Suburb' dataFormat={valueFormatter} thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Suburb</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Zip' dataFormat={valueFormatter} width='90' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Postcode</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Notes' dataFormat={valueFormatter} thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Notes</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Division' dataFormat={valueFormatter} thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Division</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Cartons' dataFormat={valueFormatter} width='85' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Number of cartons</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='DeliveryType' hidden={(jobs && jobs.length > 0 && fieldExists(jobs, 'DeliveryType') === false) || (invalidJobs && invalidJobs.length > 0 && fieldExists(invalidJobs, 'DeliveryType') === false)} dataFormat={valueFormatter} width='85' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Delivery Type</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='CubicCapacityMetresCubed' hidden={(jobs && jobs.length > 0 && fieldExists(jobs, 'CubicCapacityMetresCubed') === false) || (invalidJobs && invalidJobs.length > 0 && fieldExists(invalidJobs, 'CubicCapacityMetresCubed') === false)} dataFormat={valueFormatter} width='85' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Cubic Capacity Metres Cubed</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='LocationMobileNumber' hidden={(jobs && jobs.length > 0 && fieldExists(jobs, 'LocationMobileNumber') === false) || (invalidJobs && invalidJobs.length > 0 && fieldExists(invalidJobs, 'LocationMobileNumber') === false)} dataFormat={valueFormatter} width='85' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Location Mobile Number</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='ClientCode' hidden={(jobs && jobs.length > 0 && fieldExists(jobs, 'ClientCode') === false) || (invalidJobs && invalidJobs.length > 0 && fieldExists(invalidJobs, 'ClientCode') === false)} dataFormat={valueFormatter} width='85' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Client Code</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='ReferenceNumbers' hidden={(jobs && jobs.length > 0 && fieldExists(jobs, 'ReferenceNumbers') === false) || (invalidJobs && invalidJobs.length > 0 && fieldExists(invalidJobs, 'ReferenceNumbers') === false)} dataFormat={valueFormatter} width='85' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Reference Numbers</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='PoR' hidden={(jobs && jobs.length > 0 && fieldExists(jobs, 'PoR') === false) || (invalidJobs && invalidJobs.length > 0 && fieldExists(invalidJobs, 'PoR') === false)} dataFormat={valueFormatter} width='85' thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">PoR</span></TableHeaderColumn>
                        <TableHeaderColumn dataField='Errors' hidden={jobs !== undefined} dataFormat={errorFormatter} thStyle={{ whiteSpace: 'normal' }} tdStyle={{ whiteSpace: 'normal' }} dataSort={false}><span className="small-header-font">Error</span></TableHeaderColumn>
                    </BootstrapTable>
                    <Form.Row>
                        <Form.Group as={Col} xs={12} className="margin-top-5 margin-bottom-zero">
                            {jobs &&
                            <Form.Label>{`Total Records: ${jobs.length}`}</Form.Label>
                            }
                            {invalidJobs.length > 0 &&
                            <Form.Label>{`Total Invalid Records: ${invalidJobs.length}`}</Form.Label>
                            }
                        </Form.Group>
                        <Form.Group as={Col} xs={12}>
                            {jobs &&
                            <Button disabled={isProcessing} className="mr-sm-2 margin-bottom-15" variant="success" size="sm" onClick={processImport}>
                                <i className="fas fa-upload" style={{ fontSize: '1em' }} /> {isProcessing ? 'Processing' : 'Proceed with import'}
                            </Button>
                            }
                            {invalidJobs.length > 0 &&
                            <Button disabled={isProcessing} className="mr-sm-2 margin-bottom-15" variant="success" size="sm" onClick={download}>
                                <i className="fa fa-download" style={{ fontSize: '1em' }} /> {isProcessing ? 'Downloading' : 'Download invalid jobs'}
                            </Button>
                            }
                        </Form.Group>
                    </Form.Row>
                </React.Fragment>
            )
        }
        if(jobs === undefined && invalidJobs.length === 0 && savedJobs.length > 0)
        {
            return(
                <Form.Row>
                    <Form.Group as={Col} xs={12} className="margin-bottom-20">
                        <Alert variant='success'>
                        {'Successfully imported all ' + savedJobs.length + ' records!'}
                        </Alert>
                    </Form.Group>
                </Form.Row>
            )            
        }
    }

    const progressBar = () => {
        if(jobs && isProcessing)
        {
            return(
                <Form.Row>
                    <Form.Group as={Col} xs={12} className="margin-bottom-20">
                        <ProgressBar now={progress} label={`${progress}%`} />
                    </Form.Group>
                </Form.Row>
            )
        }
    }

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const download = async () => {
        setIsProcessing(true);
        let workSheet;
        let row;
        const workBook = new ExcelJS.Workbook();
        workSheet = workBook.addWorksheet('Invalid Jobs');
        workSheet.columns = [
            { header: 'Delivery Date', key: 'Delivery Date', width: 15 },
            { header: 'Consignment', key: 'Consignment', width: 10},
            { header: 'JobType', key: 'JobType', width: 15},
            { header: 'Location', key: 'Location', width: 10},
            { header: 'Address 1', key: 'Address 1', width: 10},
            { header: 'Address 2', key: 'Address 2', width: 10},
            { header: 'Suburb', key: 'Suburb', width: 10},
            { header: 'Postcode', key: 'Postcode', width: 10},
            { header: 'Notes', key: 'Notes', width: 10},
            { header: 'Division', key: 'Division', width: 10},
            { header: 'Number of cartons', key: 'Number of cartons', width: 10},
            { header: 'Delivery Type', key: 'Delivery Type', width: 10},
            { header: 'Delivery ConsignmentIds', key: 'Delivery ConsignmentIds', width: 10},
            { header: 'Cubic Capacity Metres Cubed', key: 'Cubic Capacity Metres Cubed', width: 10},
            { header: 'Location Mobile Number', key: 'Location Mobile Number', width: 10},
            { header: 'Client Code', key: 'Client Code', width: 10},
            { header: 'Reference Numbers', key: 'Reference Numbers', width: 20},
            { header: 'PoR', key: 'PoR', width: 10},

        ];
        invalidJobs.forEach((j,index) => {
            workSheet.addRow([j.DeliveryDate,j.ConsignmentNumber,j.JobType,j.LocationName,j.Street1,j.Street2,j.Suburb,j.Zip,j.Notes,j.Division,j.Cartons,j.DeliveryType,j.DeliveryConsignmentIds,j.CubicCapacityMetresCubed,j.LocationMobileNumber,j.ClientCode,j.ReferenceNumbers, j.PoR]);
            row = workSheet.lastRow;
            row.eachCell(function(cell, colNumber) {
                cell.border = {top: {style:'thin'},left: {style:'thin'},bottom: {style:'thin'},right: {style:'thin'}};
            });
        });
        const buf = await workBook.csv.writeBuffer()
        saveAs(new Blob([buf]), 'InvalidJobs_' + moment(new Date()).format("DD/MM/YYYY").replace("/", "_").replace("/", "_") + '.csv');
        setIsProcessing(false);
    }

    return(
        <Container className="no-left-right-margin form-text-align">
            <PostcodeDivisionCheck jobs={jobs} />
            <Card>
                <Card.Header>
                    <h4>Import Job</h4>
                </Card.Header>
                <Card.Body>
                    <Form.Row>
                        <Form.Group as={Col} xs={12}>
                            Click <span className="no-left-right-padding clickable"><a href='' onClick={downloadTemplate}>here</a></span> to download sample import file.
                        </Form.Group>
                    </Form.Row>
                    {jobsDisplay()}
                    {processError()}
                    {progressBar()}
                    <Form.Row style={{ border: 'solid thin', padding: '1em', borderColor: '#ced4da' }}>
                        <Form.Group as={Col} xs={12} className="margin-top-10 margin-bottom-zero">
                            <Form.Label><h4>{jobs ? 'Select a different file to upload' : 'Upload file'}</h4></Form.Label><br/>
                            <input type="file" name="file" key={fileRefreshToken} disabled={isProcessing} onChange={onChangeHandler}/><br/>                                
                        </Form.Group>
                        <Form.Group as={Col} xs={12} className="margin-top-20">
                            <Form.Check 
                                className='med-font'
                                defaultChecked={ignoreFirstLine}
                                label="Ignore First Line (Line is field names not data)"
                                value={ignoreFirstLine}
                                onChange={() => setIgnoreFirstLine(!ignoreFirstLine)}
                                />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} className="margin-top-10">
                            <Button disabled={(error || isProcessing) ? true : (selectedFile ? false : true)} className="mr-sm-2" variant="success" size="sm" onClick={previewUpload}>
                                <i className="fas fa-eye" style={{ fontSize: '1em' }} /> View Preview
                            </Button>
                        </Form.Group>
                    </Form.Row>
                    {infoRow()}
                </Card.Body>
            </Card>
            <Download url={templateUrl} />
        </Container>
    )
}

export default Import